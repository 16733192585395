@import "./variables.less";


.container {
  padding-left: 0px;
  @media (min-width: @screen-sm-min) {
    padding-left: 70px;
  }
}

.dashhead-title {
  color: darken(@brand-primary, 15%);
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.dashhead-subtitle {
    color: @gray;
    text-transform:none;
    font-size: 1em;
}

nav {
  &.iconav {
      -webkit-transition: left 300ms ease;
      -moz-transition: left 300ms ease;
      -ms-transition: left 300ms ease;
      -o-transition: left 300ms ease;
      transition: left 300ms ease;

      left: -70px;
  }
  &.authed {
      left: 0px;
  }
}

ul {
  &.error-messages {
      margin-bottom: 0px;
        li {
            border: 0px;
            padding: 2px 13px;
        }
    }
}

.form-control[readonly] {
    background: #434857 !important;
}

.dataTables_paginate {
    float: none !important;
    text-align: center !important;
}

.dt-loading {
    display: none !important;
}

.loginscreen {
  margin-top: 200px;
}

@media (max-width: 768px) {
    .iconav:not(.authed) .iconav-slider {
        display: none;
    }

    .iconav {
        margin-top: 0px !important;
    }
      .loginscreen {
        margin-top: 0px;
      }

}
@media (max-width 480px) {
  .loginscreen {
    margin-top: 0px;
  }
}

.datepicker-custom {
    cursor: pointer;
}

.iconav-brand {
    background-color: #f5f5f5;
}

.btn-facebook:hover {
   color: #fff;
   background-color: #2d4373;
   border-color: rgba(0,0,0,0.2);
}

.btn-google:hover {
  color: #fff;
  background-color: #dd4b39;
  border-color: rgba(0,0,0,0.2);
}

.tooltip {
  &.right{
    margin-left: 0px;
  }
}
